<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div class="p-d-flex p-ai-center p-flex-wrap">
              <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Emails') }}</div>
              <template v-if="emailsListNotEmpty">
                <AddNewButton label="New message" @click="createMessage" class="p-mr-2"/>
<!--                 <Button :disabled="refreshButtonIsClicked" @click="getNewMessages" class="p-button-raised p-mr-2" icon="pi pi-refresh"></Button>-->
                <MultipleDeleteButton
                    :label="`${$t('Delete')} ${selectedItems?.length ? `(${selectedItems?.length} items)`  : ''}`"
                    v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant"
                    @click="confirmItemsMultipleDelete"
                    :disabled="!selectedItems?.length"
                    class="p-mr-2"/>
                <div class="p-field-radiobutton">
                  <RadioButton class="radio_button" id="radio_button_all" name="messagesState" value="1" v-model="messagesState" />
                  <label class="radio_button_label" for="radio_button_all">All messages</label>
                </div>
                <div class="p-field-radiobutton">
                  <RadioButton class="radio_button" id="radio_button_new" name="messagesState" value="2" v-model="messagesState" />
                  <label class="radio_button_label" for="radio_button_new">Only new</label>
                </div>
                <div class="p-field-radiobutton" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">
                  <RadioButton class="radio_button" id="radio_button_answered" name="messagesState" value="3" v-model="messagesState" />
                  <label class="radio_button_label" for="radio_button_answered">Answered</label>
                </div>
                <div class="p-field-radiobutton" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">
                  <RadioButton class="radio_button" id="radio_button_viewed" name="messagesState" value="4" v-model="messagesState" />
                  <label class="radio_button_label" for="radio_button_viewed">Only viewed</label>
                </div>
              </template>
<!--              <Button class="p-button-danger p-button-raised p-mr-2" @click="test">TEST</Button>-->

            </div>

            <!--            <Button class="p-button-secondary p-button-raised p-mr-2" icon="pi pi-refresh"></Button>-->
          </template>
<!--          <template v-slot:right>-->
          <template #end v-if="emailsListNotEmpty">
<!--            <div class="p-input-icon-left">-->
<!--              <i class="pi pi-search" />-->
<!--              <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--            </div>-->
            <SearchInput :disabled="dataTableIsLoading || messageIsOpened"
                         :clearLastSearchingValue="clearLastSearchingValue"
                         v-model="searchData"
                         @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <div class="p-d-flex">
          <div style="width: 15%; min-width: 270px" class="p-mr-4">
            <OwnTree v-if="emailsList?.length" @node-select="changeFolder" :value="emailsList" style="overflow: hidden;" :expandedKeys="expandedKeys" selectionMode="single" v-model:selectionKeys="selectedFolder"></OwnTree>
          </div>
          <div class="" style="width: 85%;">
<!--          <div>-->
            <Spinner v-if="isLoading && !messageIsOpened"></Spinner>

            <DesktopDataTable v-if="!isLoading && !messageIsOpened"
                              :dataTableIsLoading="dataTableIsLoading"
                              :showHeader="false"
                              :tableName="$t('Emails')"
                              :itemsArrayIsEmpty="itemsArrayIsEmpty"
                              :showButtons="false"
                              :showSearchInput="false"
                              :headers="[
                {name: '', title: '', width: '', sortable: false},
                {name: 'sender_name', title: 'Sender', width: '20%', sortable: false},
                {name: 'is_read', width: '', sortable: false},
                {name: 'text', title: 'Text', width: '70%', maxWidth: '50vw', sortable: false},
                {name: 'created_at', title: 'Created at', width: '10%', sortable: false},
                // {name: '', title: '', sortable: false},
                // {name: 'sender_name', title: 'Sender', sortable: false},
                // {name: 'text', title: 'Text', sortable: false},
                // {name: 'created_at', title: 'Created at', sortable: false},
            ]">
<!--              {name: '', title: '', width: '36px', sortable: false},-->
<!--              {name: '', title: '', width: '36px', sortable: false},-->
              <template v-slot:multiselectHeader>
                <th v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant" class="multiSelect-column-header">
                  <Checkbox :disabled="dataTableIsLoading" @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>
<!--                  <Checkbox @click="selectAllCheckboxOnClick" :value="0" v-model="selectAllCheckbox"/>-->
                </th>
              </template>
              <template v-slot:body>
                <tr v-for="(item, index) of items"
                    :key="index"
                    class="messages-row"
                    :class="[{'p-highlight': isItemSelected(item.id)}, {'bold-text' : !item.is_seen}]">
                  <td :class="{ 'new-item': isItemAdded(item.id) }" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
                    <Checkbox @click.stop v-if="!isItemAdded(item.id)" :disabled="dataTableIsLoading" :class="{ 'no-pointer':isItemAdded(item.id)}" :value="item.id" v-model="selectedItems"/>
                  </td>
                  <td style="padding: 2px">
                    <span>
<!--                      <i @click.stop="toggleFlagged(item)" class="pi pi-star email-flag-btn email-flag-btn&#45;&#45;empty" :class="[{'pi pi-star-fill' : item.is_flagged}, {'theme-color' : item.is_flagged}, {'highlight&#45;&#45;link':isItemSelected(item.id)}]"></i>-->
                    </span>
                    <Button @click.stop="toggleFlagged(item)"
                            class="message-button p-button-text"
                            v-tooltip.top="openedMessage.is_flagged ? 'Unflag' : 'Flag'"
                            :icon="item.is_flagged ? 'pi pi-star-fill' : 'pi pi-star'"
                            :class="{'highlight--link':isItemSelected(item.id)}">
                    </Button>
                  </td>
                  <td @click="emailOnClick(item)">{{ !!item.from_name ? item.from_name : item.from_email }}</td>
                  <td style="padding: 2px">
                    <Button v-if="!item.is_seen"
                            @click.stop="toggleMessageIsSeen(item)"
                            class="message-button p-button-text warning-color"
                            v-tooltip.top="'Mark as seen'"
                            icon="pi pi-exclamation-circle"
                            :class="{'highlight--link':isItemSelected(item.id)}">
                    </Button>
                  </td>
                  <td @click="emailOnClick(item)">
                    <div class="p-d-flex p-ai-center p-jc-between">
<!--                      <div style="max-width: 50vw; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">-->
                      <div style="width: 50vw; max-width: 1160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                        <span v-show="item.subject">{{ item.subject }}</span>
<!--                        <span v-show="item.subject && (item.title || item.plain_text || item.html_text)"> - </span>-->
                        <span v-show="item.subject && item.title"> - </span>
                        <span v-if="item.title" style="font-size: 0.9em;" :style="{'font-weight' : !item.subject && !item.is_seen ? 500 : 300}">
<!--                          <template >-->
                          {{ item.title }}
<!--                          </template>-->
<!--                          <template v-if="item.plain_text">-->
<!--                            {{ item.plain_text }}-->
<!--                          </template>-->
<!--                          <template v-else-if="item.html_text">-->
<!--                            {{ item.html_text }}-->
<!--                          </template>-->
                        </span>
                      </div>

<!--                      <div>Plaintext length: {{ item.plain_text?.length }}, Html: {{ item.html_text?.length }}</div>-->

<!--                      <div style="">-->
<!--                        {{ item.subject }} <span style="font-size: 0.9em; font-weight: 400;"> - {{ item.plain_text || item.html_text }}</span>-->
<!--                      </div>-->
                      <div class="p-d-flex p-ai-center">
                        <div v-if="item.answers?.length"  class="p-d-flex p-ai-center" style="font-size: 0.9em; font-weight: 400;padding: 4px;">
                          <i class="ti-comment-alt" style="margin-right: 3px;"></i>
                          <div>{{ item.answers.length }}</div>
                        </div>
                        <div v-if="item.attachments?.length"  class="p-d-flex p-ai-center" style="font-size: 0.9em; font-weight: normal;padding: 4px;">
                          <i class="ti-clip" style="margin-right: 3px;"></i>
                          <div>{{ item.attachments.length }}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td @click="emailOnClick(item)">
                    <span v-if="item.created_at" style="font-weight: normal">{{ formatDate(item.created_at) }}</span>
                  </td>
                </tr>
              </template>
            </DesktopDataTable>

            <div v-else-if="messageIsOpened">
<!--              <Button icon="ti-arrow-left" @click="closeMessage"></Button>-->
<!--              <hr>-->
<!--              <div class="p-d-flex p-jc-between p-ai-center">-->
<!--                <div>Subject: <span style="font-weight: 500; font-size: 1.2em">{{ openedMessage.subject }}</span></div>-->
<!--                <div class="p-pr-4">-->
<!--                  <Button icon="ti-printer"></Button>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr>-->

              <div class="p-d-flex p-jc-between p-ai-center p-flex-wrap">
                 <div>
                   <div v-show="openedMessage.subject">Subject: <span style="font-weight: 500; font-size: 1.1em">{{ openedMessage.subject }}</span></div>
                   <div>From: {{ openedMessage.from_name }} {{ openedMessage.from_email }}</div>
                   <div>To: {{ openedMessage.to_name }} {{ openedMessage.to_email }}</div>
                   <div>
                     Created:
                     <span v-if="openedMessage.created_at">{{ formatDate(openedMessage.created_at) }}</span>
                     <span v-if="openedMessage.createdBy">, {{ openedMessage.createdBy.first_name }} {{ openedMessage.createdBy.last_name }}</span>
                   </div>
                   <div v-if="openedMessage.answers?.length">
                     Answers:
                     <span v-for="(answer, index) of openedMessage.answers" :key="answer.id">
                        <a @click.prevent="emailAnswerOnClick(answer)" class="p-link">
                          <span v-if="answer.created_at">{{ formatDate(answer.created_at) }}</span><span v-if="answer.createdBy">, {{ answer.createdBy.first_name }} {{ answer.createdBy.last_name }}</span>
                        </a>
                        <span v-if="openedMessage.answers.length > 1 && index !== openedMessage.answers.length - 1"> | </span>
                     </span>
                   </div>
                   <div v-if="openedMessage.seenBy || openedMessage.is_seen_at">
                      <span v-if="openedMessage.is_seen_at">Seen at: {{ formatDate(openedMessage.is_seen_at) }}</span>
                      <span v-if="openedMessage.seenBy">,
                       <!--                          <span v-if="openedMessage.seenBy.type === constants.userTypes.legal_entity && file.createdBy.company_name">{{ file.createdBy.company_name }}</span>-->
                        <span>{{ openedMessage.seenBy.first_name }} {{ openedMessage.seenBy.last_name }}</span>
                      </span>
                   </div>
                 </div>
                <EmailAttachmentButton :message="openedMessage"/>

                <ul class="p-mr-3">
                  <li>
                    <Button label="Reply" class="message-button p-button-text p-mr-1" icon="ti-share-alt" @click="replyMessage(openedMessage)"></Button>
                  </li>
                  <li>
                    <Button label="Forward" class="message-button p-button-text p-mr-1" icon="ti-share"  @click="forwardMessage(openedMessage)"></Button>
                  </li>
                  <li>
                    <Button class="message-button p-button-text" v-tooltip.top="openedMessage.is_seen ? 'Mark as unseen' : 'Mark as seen'" @click.stop="toggleMessageIsSeen(openedMessage)" :icon="openedMessage.is_seen ? 'pi pi-check-circle' : 'pi pi-exclamation-circle warning-color'"></Button>
                  </li>
                  <li>
                    <Button class="message-button p-button-text" v-tooltip.top="openedMessage.is_flagged ? 'Unflag' : 'Flag'" @click.stop="toggleFlagged(openedMessage)" :icon="openedMessage.is_flagged ? 'pi pi-star-fill' : 'pi pi-star'"></Button>
                  </li>
<!--                  <li>-->
<!--                    <Button icon="ti-printer" v-tooltip.top="'Print'" class="p-button-text p-mr-1" @click="closeMessage"></Button>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <Button icon="ti-na" v-tooltip.top="'Mark as spam'" class="p-button-text p-mr-1" @click="closeMessage"></Button>-->
<!--                  </li>-->
                  <li v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">
                    <Button icon="ti-trash" v-tooltip.top="'Delete'" :disabled="dataTableIsLoading" class="p-button-text p-mr-1" @click="confirmItemDelete({ id: openedMessage.id })"></Button>
                  </li>
                  <li>
                    <!--                    <Button icon="ti-angle-left" v-tooltip.top="'Go back'" class="p-button-text p-mr-1" @click="closeMessage"></Button>-->
                    <Button icon="ti-close" v-tooltip.top="$t('Close')" class="message-button p-button-text p-mr-1" @click="closeMessage"></Button>
                  </li>
                </ul>
              </div>

              <hr>

<!--              :srcdoc="openedMessage.html_text ? `<base target='_blank'>` + ($store.state.user.schema !== 'light' ? `<div style='color: #FFFFFFDE'>` : `<div style='color: #495057'>`) + openedMessage.html_text + '</div>' : openedMessage.plain_text ? `<div style='white-space: pre-wrap'>` + openedMessage.plain_text + `</div>` : ''">-->
<!--              :srcdoc="($store.state.user.schema !== 'light' ? `<div style='color: #FFFFFFDE'>` : `<div style='color: #495057'>`) + openedMessage.html_text ? `<base target='_blank'>` + openedMessage.html_text + '</div>' : openedMessage.plain_text ? `<div style='white-space: pre-wrap'>` + openedMessage.plain_text + `</div>` : ''">-->
              <div id="iframe-container" class="iframe-container">
                <iframe id="message-frame"
                        @load="calculateIframeHeight"
                        loading="lazy"
                        allow="fullscreen"
                        seamless
                        :srcdoc="computedSrcDoc">
                </iframe>
              </div>

<!--                <iframe id="message-frame" loading="lazy" base target="_blank" :srcdoc="openedMessage.html_text ? `<base target='_blank'>` + openedMessage.html_text : openedMessage.plain_text ? openedMessage.plain_text : ''" frameborder="0"></iframe>-->
<!--              <object :data="openedMessage.html_text" type="html"></object>-->
<!--              <ebmed>{{openedMessage.html_text}}</ebmed>-->


            </div>
          </div>
          <div id="myImg"></div>
        </div>
        <OwnPaginator v-show="!itemsArrayIsEmpty && !messageIsOpened"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData v-show="!messageIsOpened" :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
      </div>
    </div>

<!--    <div v-if="emailAccounts">-->
<!--      <div v-for="account of emailAccounts" :key="account.id">-->
<!--        <div>{{ account.accountData?.username }}</div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <div class="p-col-9">-->
<!--      <div class="card">-->


<!--      </div>-->
<!--    </div>-->



    <EmailModal :visible="itemModal"
                :selectedEmailId="selectedEmailId"
                :userEmailAccounts="userEmailAccounts"
                @close="closeItemModal"
                @update-message="updateMessage"
                :item="item"/>

    <ConfirmModal
        :visible="confirmItemDeleteModal"
        @close="closeConfirmItemDeleteModal"
        @confirm="deleteItem">
    </ConfirmModal>

    <ConfirmMultipleDeleteModal
        :visible="confirmItemsMultipleDeleteModal"
        @close="closeConfirmItemsMultipleDeleteModal"
        @delete-items="deleteMultipleItems">
    </ConfirmMultipleDeleteModal>

    <EmailAnswerModal
        :visible="emailAnswerModal"
        :item="emailAnswer"
        @close="closeEmailAnswerModal"/>
  </div>
</template>

<script>
import EmailModal from "@/pages/messages/components/EmailModal";
import httpClient from "@/services/http.services";
import datatableMixins from "@/mixins/datatableMixins";
import formatMixins from "@/mixins/formatMixins";
import OwnTree from "@/components/OwnTree/OwnTree";
import settings from "@/settings";
import EmailAttachmentButton from "@/pages/messages/components/EmailAttachmentButton";
import httpMixins from "@/mixins/httpMixins";
import ConfirmModal from "@/components/ConfirmModal";
import ConfirmMultipleDeleteModal from "@/components/ConfirmMultipleDeleteModal";
import EmailAnswerModal from "@/pages/messages/components/EmailAnswerModal";

export default {
  mixins: [ datatableMixins, formatMixins, httpMixins ],
  components: { EmailModal, OwnTree, EmailAttachmentButton, ConfirmModal, ConfirmMultipleDeleteModal, EmailAnswerModal },
  name: 'Messages',
  data() {
    return {
      userEmailAccountsLoaded: false,
      userEmailAccounts: null,
      emailAnswer: {},
      emailAnswerModal: false,
      srcdoc: null,
      messageIsOpened: false,
      openedMessage: {},
      // isLoading: true,
      receivedMessage: '',
      messagesState: '1',
      selectedFolder: null,
      selectedEmailId: null,
      categories: [],
      emailsList: [],
      expandedKeys: {},
      // fieldsExpand: 'attachments,answers,answers.createdBy,createdBy,seenBy',
      fieldsExpand: 'attachments,answers,answers.createdBy,createdBy,seenBy',
      apiLink: 'mail/get-messages',
      routePath: '/messages',
      refreshButtonIsClicked: false,
      deleteButtonIsClicked: false,
      getMessagesInterval: null,
      newUnansweredEmailsCount: null
    }
  },
  watch: {
    messagesState() {
      this.closeMessage()
      this.getAccountsAndItems()
    },
    // '$route'() {
    //
    //   // this.selectTreeNodeByQuery()
    //   if (this.$route.path === '/login' || this.$route.path !== '/messages') {
    //     return false
    //   }
    //   // if (!this.$route.query.email && !this.$route.query.folder && this.messageIsOpened) {
    //   //   this.messageIsOpened = false
    //   // }
    //
    //   // this.checkEmailAccountAccess(1)
    //
    //   this.checkRouteQuery()
    //   // this.selectTreeNodeByQuery()
    //   this.getItems(false)
    // },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        // if (this.itemModal) {
        //   this.closeItemModal()
        // }
        // if (this.confirmItemDeleteModal) {
        //   this.closeConfirmItemDeleteModal()
        // }
        // if (this.confirmItemsMultipleDeleteModal) {
        //   this.closeConfirmItemsMultipleDeleteModal()
        // }
        if (this.emailAnswerModal) {
          this.closeEmailAnswerModal()
        }
      }
    },
    // '$store.state.user.emailAccounts'() {
    //   if (!this.userEmailAccountsLoaded) {
    //     // this.generateEmailsTree()
    //     this.getItems(false, false, true)
    //     this.userEmailAccountsLoaded = true
    //   }
    // },
    selectedItems() {
      this.checkAllItemsAreSelected()
    }
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getAccountsAndItems(true)
    this.setGetMessagesInterval()
  },
  unmounted() {
    this.clearGetMessagesInterval()
  },
  methods: {
    // getItems() {
    //   this.getItems()
    // },
    setGetMessagesInterval() {
      const oneMinuteInMilliseconds = 60 * 1000
      // const oneMinuteInMilliseconds = 6000
      // this.getCountInterval = setInterval(() => console.log('aa'), 500)
      this.getMessagesInterval = setInterval(() => {
        const lastUnansweredEmailsCount = this.$store.state.unansweredEmailsCount
        let newUnansweredEmailsCount = null

        this.getUserEmailAccounts().then((res) => {
          if (res?.success) {
            const accounts = res.accounts
            accounts.filter(a => a.show_messages)?.forEach(emailAccount => {
                newUnansweredEmailsCount += +emailAccount.accountData.unansweredCount
              })

            if (newUnansweredEmailsCount > lastUnansweredEmailsCount) {
              // console.log('get items')
              this.generateEmailsTree()
              this.selectFirstNode()
              this.getItems(false)
            }
          // else {
              // console.log('dont get items')
            // }
          }
        })
        // console.log(this.selectedFolderNum, this.selectedFolder)
        // console.log('GetMessagesInterval')
      }, oneMinuteInMilliseconds)
    },
    // test() {
    //   const storeUserEmailAccounts = this.$store.state.user.emailAccounts
    //   if (!storeUserEmailAccounts) return false
    //
    //   console.log('selectedFolder: ', this.selectedFolder)
    //
    //   const lastUnansweredEmailsCount = this.$store.state.unansweredEmailsCount
    //   let newUnansweredEmailsCount = null
    //   this.getUserEmailAccounts().then((res) => {
    //     if (res?.success) {
    //       const accounts = res.accounts
    //       accounts.filter(a => a.show_messages)?.forEach(emailAccount => {
    //         newUnansweredEmailsCount += +emailAccount.accountData.unansweredCount
    //       })
    //
    //       if (newUnansweredEmailsCount > lastUnansweredEmailsCount) {
    //         console.log('get items')
    //         // this.generateEmailsTree()
    //         // this.selectFirstNode()
    //         // this.getMessages(false)
    //       } else {
    //       console.log('dont get items')
    //       }
    //     }
    //   })
    // },
    clearGetMessagesInterval() {
      if (!this.getMessagesInterval) return false
      clearInterval(this.getMessagesInterval)
    },
    getAccountsAndItems(showSpinner = false, getMessages = true, showToast = false) {
      this.getUserEmailAccounts().then((res) => {
        if (res?.success) {
          // if (closeMessage) {
          //   this.closeMessage()
          // }
          this.generateEmailsTree()
          this.selectFirstNode()
          if (getMessages) {
            this.getItems(showSpinner, showToast)
          }
        }
      })
    },
    // async getUserEmailAccounts(getItems = false, getUserEmailAccounts = false) {
    async getUserEmailAccounts() {
      // console.log('getUserEmailAccounts()')
      try {
        const { status, data } = await httpClient('mail/get-user-email-accounts')
        if (status === 200 && data?.length) {
          this.$store.commit('updateUserEmailAccounts', data)
          return {success: true, accounts: data}
        } else {
          this.isLoading = false
        }
      } catch(err) {
        this.showError(err)
        this.isLoading = false
      }
      // finally {
      //   this.isLoading = false
      // }
      // finally {
      //   this.generateEmailsTree(getItems, getUserEmailAccounts)
      // }
    },
    // async getUnansweredEmailsCount() {
    //   try {
    //     const { status, data } = await httpClient('mail/get-unanswered-emails-count')
    //     if (status === 200) {
    //       this.$store.commit('updateUnansweredEmailsCount', data)
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    emailAnswerOnClick(answer) {
      // console.log(answer)
      this.emailAnswer = { ...answer }
      this.emailAnswerModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeEmailAnswerModal() {
      this.emailAnswer = {}
      this.emailAnswerModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    // checkAccountAndFolderAccess() {
    //   const id = this.selectedEmailId
    //   // let accessDenied = false
    //   if (this.$store.state.user.emailAccounts.length) {
    //     const userEmailAccountsIds = this.$store.state.user.emailAccounts.map(account => (
    //         account.accountData?.id
    //     ))
    //     // accessDenied = (userEmailAccountsIds.indexOf(id) == -1)
    //   }
    //
    //   // if (this.$route.query.folder && +this.$route.query.folder !== 1 && +this.$route.query.folder !== 2 && +this.$route.query.folder !== 4) {
    //   //   accessDenied = true
    //   // }
    //   // return accessDenied
    //   return false
    // },
    calculateIframeHeight() {
      const messageFrame = document.getElementById('message-frame')

      const scrollHeight = messageFrame.contentWindow.document.body.scrollHeight
      // const offsetHeight = messageFrame.contentWindow.document.body.offsetHeight
      // const clientHeight = messageFrame.contentWindow.document.body.clientHeight
      if (messageFrame) {
        if (messageFrame.contentWindow.document.body.scrollHeight > 0) {
          messageFrame.style.height = scrollHeight + 65 + 'px'
        }
      }
    },
    replyMessage(message) {
      // console.log(message)
      this.item = {
        ...message,
        reply: true,
        forward: false,
        to_email: message.from_email
      }
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
      // console.log('Reply: ' + this.message.reply, 'Forward: ' + this.message.forward)
    },
    forwardMessage(message) {
      this.item = {
        ...message,
        reply: false,
        forward: true,
      }
      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
      // console.log('Reply: ' + this.message.reply, 'Forward: ' + this.message.forward)
    },
    async deleteItem() {
      this.deleteButtonIsClicked = true
      if (!this.itemToDelete) {
        return false
      }
      try {
        const { status } = await httpClient.post('mail/delete-message', { id: this.itemToDelete.id })
        if (status === 204) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: settings.toastLife});
          this.closeConfirmItemDeleteModal()
          // this.getItems()
          this.getAccountsAndItems()
          this.closeMessage()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    async deleteMultipleItems() {
      try {
        const { status } = await httpClient.post('mail/delete-multiple-messages', { ids: this.selectedItems })
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data deleted'), life: settings.toastLife});
          // this.searchData = ''

          // this.getItems()
          this.getAccountsAndItems()
          this.closeConfirmItemsMultipleDeleteModal()
          this.unselectAllItems()
          this.closeMessage()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    updateMessage(message) {
      if (!message) return false

      if (this.selectedFolderNum === 2 && this.selectedEmailId === message.email_account_id) {
        // this.getNewMessages()
        // this.getItems(false, true, false, false)
        this.getAccountsAndItems(false, true, false, false)
      }

      if (message.is_reply) {
        if (this.messageIsOpened && this.openedMessage) {
          this.openedMessage.is_seen = 1
        }
        // this.getItems()
        // this.getItems(false, true, false, false)
        this.getAccountsAndItems(false, true, false, false)

        const answer = {
          html_text: message.html_text,
          plain_text: message.plainText,
          createdBy: {
            first_name: this.$store.state.user?.first_name,
            last_name: this.$store.state.user?.last_name,
          },
          created_at: +new Date() / 1000
        }

        if (this.openedMessage) {
          this.openedMessage.answers.push(answer)
        }
      }
    },
    // selectMessagesType(num) {
    //   switch (num) {
    //     case 1:
    //       this.selectedMessagesType = 1
    //       break
    //     case 3:
    //     case 4:
    //       this.selectedMessagesType = null
    //       break
    //     case 2:
    //       this.selectedMessagesType = 2
    //       break
    //     default:
    //       this.selectedMessagesType = null
    //   }
    // },
    changeFolder(node) {
      if (this.messageIsOpened) {
        this.closeMessage()
      }

      if (!node || (node.folder === this.selectedFolderNum && node.email === this.selectedEmailId)) {
        return false
      }
      // this.searchData = null
      // this.processedSearchValue = null
      this.clearLastSearching()

      this.unselectAllItems()
      // this.openedMessage = {}
      // this.messageIsOpened = false
      // this.selectMessagesType(node.folder)

      // const emailId = node.key.split('-')[0]
      // this.selectedEmailId = emailId
      this.selectedEmailId = node.email
      this.selectedFolderNum = node.folder
      this.isLoading = true
      this.messageIsOpened = false
      // this.getItems(true, true, false, true)
      // this.getItems(true)
      this.getAccountsAndItems(true)

      this.resetRouteAndPagination()
      // this.closeMessage()


      // const route = {
      //   route: this.$route.path,
      //   query: {
      //     ...this.$route.query,
      //     email: node.email,
      //     folder: node.folder
      //   }
      // }
      // this.$router.push(route)
    },
    // selectTreeNodeByQuery() {
    //   if (this.$route.query.email && this.$route.query.folder) {
    //     this.selectedEmailId = +this.$route.query.email
    //     this.selectedFolderNum = +this.$route.query.folder
    //     let obj = {}
    //     const key = this.$route.query.email + '-' + this.$route.query.folder
    //     obj[key] = true
    //     this.selectedFolder = obj
    //     // this.selectMessagesType(+this.$route.query.folder)
    //     // this.selectedEmailId = +this.$route.query.email
    //     // const key = this.emailAccounts[0]?.children?.filter(child => child.id === +this.$route.query.email)[0]?.key
    //     // if (key)  {
    //     //   const obj = {}
    //     //   obj[key] = true
    //     //   this.selectedFolder = {...obj}
    //     // } else {
    //     //   this.selectedFolder = { 0: true }
    //     //   this.selectedCategoryId = null
    //     // }
    //   } else {
    //     // if (this.emailsList.length && !this.$route.query.email && !this.$route.query.folder) {
    //     this.selectFirstNode()
    //     // }
    //   }
    // },
    // selectFirstNode(getItems = false, getUserEmailAccounts = false) {
    selectFirstNode() {
      // console.log('selectFirstNode')
      // if (!this.selectedEmailId && !this.selectedEmailId) {
      //   this.selectedFolder = {'1-1': true}
      //   this.selectedEmailId = 1
      //   this.selectedFolderNum = 1
      // }
      if (!this.selectedEmailId && this.emailsListNotEmpty && !this.$route.query.email && !this.$route.query.folder) {
        const key = this.emailsList[0]?.children?.[0].key
        const splittedKey = key?.split('-')
        this.selectedEmailId = +splittedKey[0]
        this.selectedFolderNum = +splittedKey[1]

        let obj = {}
        obj[key] = true
        this.selectedFolder = obj

        this.resetRouteAndPagination()
        // console.log(route)
        //
        // if (this.$route.query.page) {
        //   delete route.query.page
        // }
        // if (this.$route.query.perPage) {
        //   delete route.perPage
        // }


      }

      // if (getItems) {
      //   this.getItems(false, false, getUserEmailAccounts)
      // }
    },
    emailOnClick(item) {
      if (!item) return false
      this.getEmailText(item)
      // this.toggleMessageIsSeen(item)
    },
    async getEmailText(item) {
      this.isLoading = true
      try {
        const { status, data } = await httpClient.post('mail/get-text?expand=html_text,plain_text,answers,answers.plain_text,answers.html_text,answers.createdBy,createdBy,seenBy', { id: item.id })
        if (status === 200 && data) {
          this.messageIsOpened = true
          this.openedMessage = item
          this.openedMessage.plain_text = data.plain_text
          this.openedMessage.html_text = data.html_text
          this.openedMessage.answers = data.answers
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
      }
     },
    async toggleMessageIsSeen(message) {
      if (!message) return false
      message.is_seen = !message.is_seen
      try {
        const { status } = await httpClient.post('mail/toggle-seen', { id: message.id, state: message.is_seen })
        if (status === 200) {
          this.getAccountsAndItems(false, false)
        } else {
          message.is_seen = !message.is_seen
        }
      } catch(err) {
        this.showError(err)
        message.is_seen = !message.is_seen
      } finally {
        if (message.is_seen) {
          message.is_seen_at = Date.now() / 1000,
          message.seenBy = {
            first_name: this.$store.state.user?.first_name,
            last_name: this.$store.state.user?.last_name,
          }
        } else {
          message.is_seen_at = null,
          message.seenBy = null
        }
      }
    },
    closeMessage() {
      this.messageIsOpened = false
      this.openedMessage = {}
      this.item = {}
    },
    async toggleFlagged(item) {
      item.is_flagged = !item.is_flagged
      try {
        const { status } = await httpClient.post('mail/toggle-flagged', { id: item.id, state: item.is_flagged })
        if (status === 200) {
          if (this.selectedEmailId === item.email_account_id && this.selectedFolderNum === 4) {
            this.items = this.items.filter(i => i.id !== item.id)
          }
          this.getAccountsAndItems(false, false)
        } else {
          item.is_flagged = !item.is_flagged
        }
      } catch(err) {
        this.showError(err)
        item.is_flagged = !item.is_flagged
      }
    },
    async getNewMessages() {
      this.isLoading = true
      // this.dataTableIsLoading = false
      this.refreshButtonIsClicked = true
      try {
        const { status, data } = await httpClient.post('mail/get-new-messages')
        if (status === 200) {
          if (data?.success) {
            this.receivedMessage = data
          }
          // this.getUnansweredEmailsCount()
        }
      } catch(err) {
        // this.isLoading = false
        this.showError(err)
      } finally {
        this.getAccountsAndItems(true, true, true)
      }
    },
    generateEmailsTree() {
      // console.log('generateEmailsTree')
      const userAccounts = this.$store.state.user.emailAccounts
      if (!userAccounts?.length) return false

      this.userEmailAccounts = userAccounts.map(account => (
          {id: account.accountData?.id, username: account.accountData?.username, sender_name: account.accountData?.sender_name, signature: account.signature}
      ))

      // const userAccounts = this.$store.state.user.emailAccounts
      // console.log(userAccounts)
      // const accountsWithShowMessages =  userAccounts?.filter(account => account.show_messages).sort((a, b) => b.id - a.id)
      const accountsWithShowMessages =  userAccounts?.filter(account => account.show_messages)
      if (accountsWithShowMessages) {
        const accountsCategoryList = []
        accountsWithShowMessages.forEach(account => {
          const emailId = account.accountData.id
          accountsCategoryList.push({
            key: emailId, label: account.accountData.username, style: 'text-transform: lowercase', styleClass: 'tree-node tree-node--bold', selectable: false, children: [
              {key: emailId + '-1', label: 'Inbox', email: emailId, folder: 1, unansweredMessages: account.accountData.unansweredCount, icon: 'ti-import'},
              {key: emailId + '-2', label: 'Sent', email: emailId, folder: 2,icon: 'ti-export'},
              // {key: emailId + '-3', label: 'Spam', email: emailId, folder: 3,icon: 'ti-na'},
              {key: emailId + '-4', label: 'Starred', email: emailId, folder: 4, starredMessages: account.accountData.starredCount, icon: 'ti-star'},
            ]
          })
          this.expandedKeys[emailId] = true
        })
        this.emailsList = accountsCategoryList
      }
    },
    async getItems(showSpinner = false, showToast = false) {
      // this.selectFirstNode()
      // console.log(this.$route.query.email)
      // if (this.checkAccountAndFolderAccess()) {
      //   this.items = []
      //   this.isLoading = false
      //   console.log('access denied')
      //   return false
      // }
      // this.dataTableIsLoading = true
      this.dataTableIsLoading = true
      // console.log('get items')
      // this.refreshButtonIsClicked = false
      // this.deleteButtonIsClicked = false
      this.isLoading = showSpinner

      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            folder: this.selectedFolderNum,
            emailAccountId: this.selectedEmailId,
            messagesState: this.messagesState,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand
          }
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data
          // console.log(data)

          if (!data?.length) {
            this.pagination.pageCount = 1
            // if (showToast) {
            //   this.$toast.add({severity: 'info', detail: 'There is no new messages', life: settings.toastLife});
            // }
          }

          if (showToast) {
            this.$toast.add({severity: 'success', detail: 'Messages received', life: settings.toastLife});
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.refreshButtonIsClicked = false
        this.deleteButtonIsClicked = false
        this.isLoading = false
        // console.log('messages received')
        this.dataTableIsLoading = false
        this.checkAllItemsAreSelected()
      }
    },
    createMessage() {
      this.item = {
        reply: false,
        forward: false,
        // html_text: '',
        // plain_text: '',
      }

      this.itemModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    // closeItemModal() {
    //   this.itemModal = false
    //   if (this.$store.state.firstLayerIsOpened === true ) {
    //     this.$store.commit('toggleFirstLayer', false)
    //   }
    //   // this.message = {}
    // },
    // generateSrcDoc(openedMessage) {
    //   const textColorDiv = this.$store.state.user.schema === 'light' ? '<div style="color: #495057">' : '<div style="color: #FFFFFFDE">'
    //   console.log(textColorDiv)
    //
    //   if (openedMessage.html_text) {
    //     return '<base target="_blank">' + textColorDiv + openedMessage.html_text + '</div>'
    //     // return textColorDiv + this.openedMessage.html_text + '</div>'
    //   } else if (openedMessage.plain_text) {
    //     return  '<base target="_blank">' + '<span style="white-space: pre-wrap">' + textColorDiv + openedMessage.plain_text + '<span>' + '</div>'
    //   }
    // }
  },
  computed: {
    emailsListNotEmpty() {
      return !!this.emailsList?.length
    },
    computedSrcDoc() {
//       let messageSrcDoc = `<div>
//                   <div>Subject: <span style="font-weight: 500; font-size: 1.1em">{{ openedMessage.subject }}</span></div>
// <!--                  <div>From: {{ openedMessage.from_name }} {{ openedMessage.from_email }}</div>-->
// <!--                  <div>To: {{ openedMessage.to_name }} {{ openedMessage.to_email }}</div>-->
// <!--                  <div>Created: <span v-if="openedMessage.created_at">{{ formatDate(openedMessage.created_at) }}</span></div>-->
//                 </div>`

      let messageSrcDoc = ''
      if (!Object.keys(this.openedMessage)?.length) return false
      const textColorDiv = this.$store.state.user?.schema === 'light' ? '<div style="color: #495057">' : '<div style="color: #FFFFFFDE">'
      // const textColorDiv = this.$store.state.user?.schema === 'light' ? '<div>' : '<div style="background-color:#FFFFFFDE; padding: 5px">'
      // const textColorDiv = this.$store.state.user?.schema === 'light' ? '<div class="light-schema-text-color">' : '<div class="dark-schema-text-color">'
      // const textColorDiv = this.$store.state.user.schema === 'light' ? '<div style="color: red">' : '<div style="color: green">'
      if (this.openedMessage.html_text) {
        messageSrcDoc += '<base target="_blank">' + textColorDiv + this.openedMessage.html_text + '</div>'
        // return textColorDiv + this.openedMessage.html_text + '</div>'
      } else if (this.openedMessage.plain_text) {
        messageSrcDoc += '<base target="_blank">' + '<span style="white-space: pre-wrap;">' + textColorDiv + this.openedMessage.plain_text + '<span>' + '</div>'
      }

      return messageSrcDoc
    }
    // generateSrcDoc(openedMessage) {
    //   const textColorDiv = this.$store.state.user.schema === 'light' ? '<div style="color: #495057">' : '<div style="color: #FFFFFFDE">'
    //   console.log(textColorDiv)
    //
    //   if (openedMessage.html_text) {
    //     return '<base target="_blank">' + textColorDiv + this.openedMessage.html_text + '</div'
    //   }
    //   // else if (openedMessage.plain_text) {
    //   //   return (isSchemaLight ? '<div style="color: #495057">' : '<div style="color: #FFFFFFDE">') + this.openedMessage.plain_text + '</div>'
    //   // }
    //
    //   return 'hz'
    // }
  }
}
</script>

<style scoped lang="scss">
.p-field-radiobutton {
  margin-bottom: 0;
  margin-left: 15px;
  flex-wrap: wrap;
}
.radio_button {
  /*background-color: red;*/
  /*padding: 5px;*/
}
.radio_button_label {
  /*background-color: green;*/
  margin-left: 5px;
  padding: 7px 7px 7px 0;
  cursor: pointer;
}
.messages-row {
  td {
    height: 50px;
  }
  &:hover {
    cursor: pointer;
    //box-shadow: 10px 10px 10px red;
  }
}

.email-flag-btn {
  font-size: 1.1em;
  border-radius: 5px;
  padding: 10px;
  &:hover {
    //background-color: transparentize($primaryColor, 0.85);
    opacity: 0.7;
  }
}

.bold-text {
  font-weight: 500;
}
//.regular-text {
//  font-weight: 500;
//}

.iframe-container {
  //position: relative;
  //padding-bottom: 56.25%; /* 16:9 */
  ////padding-bottom: 32.25%; /* 16:9 */
  //padding-top: 25px;
  //height: 0;
}

.iframe-container iframe {
  border: 0;
  padding: 0;
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 350px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  li {
    margin: 0 3px;
    //&:after {
    //  content: '';
    //  padding: 1px;
    //  display: block;
    //  height: 15px;
    //  width: 1px;
    //  color: red;
    //}
  }
}

//.light-schema-text-color {
//  //color: #495057!important;
//  color: red!important;
//  border: 3px solid red!important;
//}
//
//.dark-schema-text-color {
//  //color: #FFFFFFDE!important;
//  color: red!important;
//  border: 3px solid red!important;
//}

</style>